/* /src/components/auth/logoutCallback.jsx */

import React from "react";
import { AuthConsumer } from "./authContext";
import { Loading } from "../component/generic/Loading";

export const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return Loading()
;
        }}
    </AuthConsumer>
);