import { combineReducers } from 'redux';
import httpReducer from './httpReducer'
import { reducer as formReducer } from "redux-form";
import { routerReducer } from 'react-router-redux';
import searchReducer from './searchReducer'
import uploadReducer from './uploadReducer'
import fileReducer from './fileReducer'
import pinReducer from './pinReducer'

import featuresReducer from './modules/features/featuresReducer'
import resourcesReducer from './modules/resources/resourcesReducer'
import areasReducer from './modules/areas/areasReducer'
import typesReducer from './modules/types/typesReducer'
import importerReducer from './modules/importer/importerReducer'
import claimReducer from './modules/claim/claimReducer'
import profileReducer from './modules/profile/profileReducer'
import eventReducer from './modules/event/eventReducer'
import clientAppReducer from './modules/client-app/clientAppReducer'
import koboImporterReducer from './modules/kobo-importer/koboImporterReducer'
import userReducer from './modules/user/userReducer'
import dmoImporterReducer from './modules/dmo-importer/dmoImporterReducer'


export default combineReducers({
    httpReducer,
    searchReducer, fileReducer, uploadReducer, pinReducer,
    routing: routerReducer,
    form: formReducer,
    featuresReducer,
    resourcesReducer,
    areasReducer,
    typesReducer,
    importerReducer,
    claimReducer,
    profileReducer,
    eventReducer,
    clientAppReducer,
    koboImporterReducer,
    dmoImporterReducer,
    userReducer

});
