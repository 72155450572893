
const types = {

    HTTP_PENDING_CLIENT_APP: 'HTTP_PENDING_CLIENT_APP',
    HTTP_ERROR_CLIENT_APP: 'HTTP_ERROR_CLIENT_APP',
    HTTP_SUCCESS_CLIENT_APP: 'HTTP_SUCCESS_CLIENT_APP',

   
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_CLIENT_APP,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_CLIENT_APP,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_CLIENT_APP,
        payload: payload
    }),

    
}



export { types, actions };
