
const types = {

    HTTP_PENDING_CLAIM: 'HTTP_PENDING_CLAIM',
    HTTP_ERROR_CLAIM: 'HTTP_ERROR_CLAIM',
    HTTP_SUCCESS_CLAIM: 'HTTP_SUCCESS_CLAIM',

   
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_CLAIM,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_CLAIM,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_CLAIM,
        payload: payload
    }),

    
}



export { types, actions };
