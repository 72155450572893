import { types } from '../actions';

const initialState = {
    pendingSearch: false,
    listResources: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HTTP_PENDING_SEARCH:
            return {
                ...state,
                pendingSearch: true
            }
        case types.HTTP_SUCCESS_SEARCH:
            return {
                ...state,
                pendingSearch: false,
                listResources: action.listResources
            }


        default:
            return state;
    }
}

