/* /src/components/auth/silentRenew.jsx */

import React from "react";
import { AuthConsumer } from "./authContext";
import { Loading } from "../component/generic/Loading";
export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return Loading()
                ;
        }}
    </AuthConsumer>
);