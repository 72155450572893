import { types } from './clientAppActions';

const initialState = {
    pending: false,
    payload: {},
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HTTP_PENDING_CLIENT_APP:
            return {
                ...state,
                pending: true
            }
        case types.HTTP_SUCCESS_CLIENT_APP:
            return {
                ...state,
                pending: false,
                payload: action.payload
            }

        case types.HTTP_ERROR_CLIENT_APP:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}

