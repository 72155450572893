import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { store } from './core/helper/store';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'

//Identity
import { Logout } from './core/identitty/logout';
import { LogoutCallback } from './core/identitty/logoutCallback';
import { SilentRenew } from './core/identitty/silentRenew';
import { Callback } from './core/identitty/callback';
import { PrivateRoute } from './core/identitty/privateRoute';
import { AuthProvider } from './core/identitty/authContext';
import { Loading } from './core/component/generic/Loading';
import { PrivacyPolicy } from './core/component/generic/PrivacyPolicy';



// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));




class App extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Provider store={store}>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <AuthProvider>
          <Router>
            <React.Suspense fallback={Loading()}>
              <Switch>
                <Route exact={true} path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact={true} path="/signin-oidc" component={Callback} />
                <Route exact={true} path="/logout" component={Logout} />
                <Route exact={true} path="/logout/callback" component={LogoutCallback} />
                <Route exact={true} path="/silentrenew" component={SilentRenew} />
                <PrivateRoute path="/login" component={PrivateRoute} />
                <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
              </Switch>
            </React.Suspense>
          </Router>
        </AuthProvider>
      </Provider>
    );
  }
}

export default App;
