
const types = {

    HTTP_PENDING_USER: 'HTTP_PENDING_USER',
    HTTP_ERROR_USER: 'HTTP_ERROR_USER',
    HTTP_SUCCESS_USER: 'HTTP_SUCCESS_USER',

   
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_USER,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_USER,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_USER,
        payload: payload
    }),

    
}



export { types, actions };
