import { types } from '../actions';

const initialState = {
    listFiles: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FILE_ADD:
            let listAdd = state.listFiles
            listAdd.push(action.file)
            return {
                ...state,
                listFiles: listAdd
            }
        case types.FILE_REMOVE:
            let listDelete = state.listFiles
            let index = listDelete.findIndex(data => data === action.item);
            listDelete.splice(index, 1)
            return {
                ...state,
                listFiles: listDelete
            }

        case types.FILE_INIT:           
            return {
                ...state,
                listFiles: action.list
            }


        default:
            return state;
    }
}

