


export const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_IDENTITY_BACKEND, //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: process.env.REACT_APP_REDIRECT_URL+"/signin-oidc/", //The URI of your client application to receive a response from the OIDC provider.
    login: process.env.REACT_APP_IDENTITY_BACKEND + "/oauth/signin",
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    post_logout_redirect_uri:  `${process.env.REACT_APP_REDIRECT_URL}/logout/callback/`, // (string): The OIDC post-logout redirect URI.
    response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    grantType: "implicit",
    scope: process.env.REACT_APP_SCOPE, //(string, default: 'openid'): The scope being requested from the OIDC provider.
    webAuthResponseType: "id_token token"
};

export const METADATA_OIDC = {
    issuer: process.env.REACT_APP_IDENTITY_BACKEND,
    jwks_uri: process.env.REACT_APP_IDENTITY_BACKEND + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/userinfo",
    end_session_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/endsession",
    check_session_iframe: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/revocation",
    introspection_endpoint: process.env.REACT_APP_IDENTITY_BACKEND + "/connect/introspect"
}
