import React, { } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
} from 'reactstrap';



export const PrivacyPolicy = () => {

    const pdfPath = process.env.PUBLIC_URL + "/Privacy_Policy.pdf"

    return (
        <div className="animated fadeIn">

            <Card>

                <CardBody>
                    <embed src={pdfPath} width={window.innerWidth} height={window.innerHeight} type="application/pdf" />
                </CardBody>
            </Card>
        </div >
    );
}






