/* /src/components/auth/logout.jsx */

import React from "react";
import { AuthConsumer } from "./authContext";
import { Loading } from "../component/generic/Loading";

export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout();
            return Loading()
;
        }}
    </AuthConsumer>
);