import { types } from '../actions';

const initialState = {
    pin: 312019,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PIN:
            return {
                ...state,
                pin: action.pin
            }


        default:
            return state;
    }
}

