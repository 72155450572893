/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "./authContext";
import { Loading } from "../component/generic/Loading";

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return Loading()
;
        }}
    </AuthConsumer>
);