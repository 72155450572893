import { types } from './profileActions';

const initialState = {
    pending: false,
    pendingChangePaswword: false,
    payload: {},
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HTTP_PENDING_PROFILE:
            return {
                ...state,
                pending: true
            }
        case types.HTTP_SUCCESS_PROFILE:
            return {
                ...state,
                pending: false,
                payload: action.payload
            }

        case types.HTTP_ERROR_PROFILE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case types.HTTP_PENDING_PROFILE_PASSWORD:
            return {
                ...state,
                pendingChangePaswword: true
            }
        case types.HTTP_SUCCESS_PROFILE_PASSWORD:
            return {
                ...state,
                pendingChangePaswword: false,
            }
        default:
            return state;
    }
}

