
const types = {

    HTTP_PENDING_AREAS: 'HTTP_PENDING_AREAS',
    HTTP_ERROR_AREAS: 'HTTP_ERROR_AREAS',
    HTTP_SUCCESS_AREAS: 'HTTP_SUCCESS_AREAS',

 

 
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_AREAS,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_AREAS,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_AREAS,
        payload: payload
    }),


   
}



export { types, actions };
