import { types } from '../actions';

const initialState = {
    pending: false,
    file: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.HTTP_PENDING_UPLOAD:
            return {
                ...state,
                pending: true
            }
        case types.HTTP_SUCCESS_UPLOAD:
            return {
                ...state,
                pending: false,
                file: action.file
            }
        case types.HTTP_ERROR_UPLOAD:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        default:
            return state;
    }
}

