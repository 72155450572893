
const types = {

    HTTP_PENDING_PROFILE: 'HTTP_PENDING_PROFILE',
    HTTP_ERROR_PROFILE: 'HTTP_ERROR_PROFILE',
    HTTP_SUCCESS_PROFILE: 'HTTP_SUCCESS_PROFILE',

 
    HTTP_PENDING_PROFILE_PASSWORD: 'HTTP_PENDING_PROFILE_PASSWORD',
    HTTP_SUCCESS_PROFILE_PASSWORD: 'HTTP_SUCCESS_PROFILE_PASSWORD',

 
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_PROFILE,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_PROFILE,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_PROFILE,
        payload: payload
    }),

    //HTTP
    startChangePassword: () => ({
        type: types.HTTP_PENDING_PROFILE_PASSWORD,
    }),
    stopChangePassword: () => ({
        type: types.HTTP_SUCCESS_PROFILE_PASSWORD,
    }),


   
}



export { types, actions };
