
const types = {

    HTTP_PENDING_FEATURES: 'HTTP_PENDING_FEATURES',
    HTTP_ERROR_FEATURES: 'HTTP_ERROR_FEATURES',
    HTTP_SUCCESS_FEATURES: 'HTTP_SUCCESS_FEATURES',
 
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_FEATURES,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_FEATURES,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_FEATURES,
        payload: payload
    }),

   
}



export { types, actions };
