
const types = {

    HTTP_PENDING: 'HTTP_PENDING',
    HTTP_ERROR: 'HTTP_ERROR',
    HTTP_SUCCESS: 'HTTP_SUCCESS',



    HTTP_PENDING_SEARCH: 'HTTP_PENDING_SEARCH',
    HTTP_SUCCESS_SEARCH: 'HTTP_SUCCESS_SEARCH',

 

    PIN: 'PIN',

    UPDATE_USER_DATA: 'UPDATE_USER_DATA',

}

const actions = {




    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS,
        payload: payload
    }),

    //Searching   
    startSearch: () => ({
        type: types.HTTP_PENDING_SEARCH,
    }),
    stopSearch: (listResources) => ({
        type: types.HTTP_SUCCESS_SEARCH,
        listResources: listResources
    }),

   

    //PIN
    updatePIN: (pin) => ({
        type: types.PIN,
        pin: pin
    }),

     //UserData
     updateUserData: (userData) => ({
        type: types.UPDATE_USER_DATA,
        userData: userData
    }),

}



export { types, actions };
