
const types = {

    HTTP_PENDING_RESOURCES: 'HTTP_PENDING_RESOURCES',
    HTTP_ERROR_RESOURCES: 'HTTP_ERROR_RESOURCES',
    HTTP_SUCCESS_RESOURCES: 'HTTP_SUCCESS_RESOURCES',

    
}
const actions = {
    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING_RESOURCES,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR_RESOURCES,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS_RESOURCES,
        payload: payload
    }),

    
}



export { types, actions };
